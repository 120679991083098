export default {
  reqres: {
    activateTelegramBot: 'telegramBot:activate',
    attachFilesToEntity: 'attachment:attach:to:entity',
    attachDocsToEntity: 'document:attach:to:entity',
    attachmentUpload: 'attachment:upload',
    authEntity: 'auth:entity',
    availabilityZone: 'spatialSearch:availabilityZone',
    baseLayers: 'baseLayers',
    baseLayersCollectionEntity: 'baseLayersCollection:entity',
    businessHoursCollectionEntity: 'businessHours:collection:entity',
    collectionEntity: 'collection:entity',
    geocodeAddress: 'geocoder:geocode:address',
    geocoderProvider: 'geocoder:provider',
    entityStatusHistoryRequest: 'entity:statusHistory:request',
    entityDocumentsCollection: 'entity:documents:collection:entity',
    entityDocumentModel: 'entity:documents:model:entity',
    integrationSettingsEntity: 'integrationSettings:entity',
    mapAddLayer: 'map:layer:add',
    mapBounds: 'map:get:bounds',
    mapFilters: 'map:filters',
    menuCollectionEntity: 'menuCollection:entity',
    modelEntity: 'model:entity',
    nearestVehicleCollection: 'nearestVehicle:collection',
    passwordResetConfirm: 'auth:password:reset:confirm',
    rawSensorCollectionEntity: 'rawSensorCollection:entity',
    recentEventCollectionEntity: 'recentEventCollection:entity',
    requestPasswordReset: 'auth:request:password:reset',
    requestVerifyEmail: 'auth:request:verify:email',
    reverseGeocode: 'geocoder:geocode:reverse',
    roadEventCollectionEntity: 'roadEventCollection:entity',
    seasonsCollectionEntity: 'seasonsCollection:entity',
    signupEntity: 'signup:entity',
    signupRegister: 'signup:register',
    spatialSearchEntity: 'spatialSearch:entity',
    systemSettings: 'systemSettings:get',
    taskGoalRequest: 'task:goal:request',
    taskStatusHistoryRequest: 'task:statusHistory:request',
    taskSummaryEntity: 'taskSummary:entity',
    timeZoneCollectionEntity: 'timeZone:collection:entity',
    timeZoneName: 'timeZone:name',
    trackLegendCollectionEntity: 'trackLegend:collection:entity',
    trackPlaybackEntity: 'trackPlayback:entity',
    trackPointCollection: 'track:point:collection',
    trackStatsEntity: 'trackStats:entity',
    trackUIStateEntity: 'trackUIState:entity',
    trackerExtEntity: 'trackerExt:entity',
    trackerPaintCollectionEntity: 'trackerPaint:collection:entity',
    trackerRawSensorCollectionEntity: 'tracker:rawSensorCollection:entity',
    trackerSensorCollectionEntity: 'tracker:sensorCollection:entity',
    undeleteEntity: 'entity:undelete',
    verifyEmail: 'auth:verify:email',
    waybillJobCollectionEntity: 'waybillJobCollection:entity',
    waybillJobEntity: 'waybillJob:entity',
    waybillStatsEntity: 'waybillStats:entity',
    waybillSummaryEntity: 'waybillSummary:entity',
  },

  command: {
    animateTrackPlayback: 'animate:trackPlayback',
    callByZadarmaWebRTC: 'call:by:zadarma:webRTC',
    fadeMapBaseLayer: 'fade:map:base:layer',
    finishTrackPlayback: 'finish:trackPlayback',
    hideMapSidebar: 'hide:map:sidebar',
    hideRoadEvents: 'hide:roadEvents',
    hideTrackPlayback: 'hide:trackPlayback',
    mapFlyTo: 'map:flyTo',
    mapPanTo: 'map:panTo',
    mapSetLayers: 'map:set:layers',
    mapSetBaseLayer: 'map:set:base:layer',
    runTrackPlayback: 'run:trackPlayback',
    setNavigation: 'set:navigation',
    showAuthView: 'show:auth:view',
    showIncommingCall: 'show:incommingCall',
    showLayerInfo: 'show:layer:info',
    showMapSidebar: 'show:map:sidebar',
    showPushNotification: 'show:pushNotification',
    showRoadEvents: 'show:roadEvents',
    showTrackPlayback: 'show:trackPlayback',
  },

  event: {
    accountTariffChange: 'account:tariff:change',
    accountTariffChanged: 'account:tariff:changed',
    accountUserUpdate: 'account:user:update',
    accountNewPayment: 'account:payment:new',
    appStartup: 'app:startup',
    appStartupComplete: 'app:startup:complete',
    authShow: 'auth:show',
    authLoggedIn: 'auth:loggedin', // like sign in, but only when manually logged in(not on every user info get)
    authSignin: 'auth:signin',
    authSignout: 'auth:signout',
    authSignoutAuto: 'auth:signout:auto',
    authSignup: 'auth:signup',
    authSignupRequested: 'auth:signup:requested',
    canvasCreated: 'canvas:created',
    dashboardShow: 'dashboard:show',
    hideLayer: 'map:layer:hide',
    i18changeLanguage: 'i18:change:language',
    i18getCurrentLanuage: 'i18:get:current:language',
    i18getLanguages: 'i18:get:language',
    mapFiltersChange: 'map:filter:change',
    mapSearch: 'map:search',
    mapShow: 'map:show',
    mapShown: 'map:shown',
    openInitialApp: 'open:initial:app',
    recentEventsHide: 'recentEvents:hide',
    recentEventsShow: 'recentEvents:show',
    routeNavigate: 'route:navigate',
    routesSearchShowResults: 'routesSearch:results:show',
    showLayer: 'map:layer:show',
    spatialSearchShowResults: 'spatialSearch:results:show',
    trackAddToMap: 'track:map:add',
    trackExport: 'track:export',
    trackHide: 'track:hide',
    trackLoad: 'track:load',
    trackLoaded: 'track:loaded',
    trackPlaybackShow: 'trackPlayback:show',
    trackPlaybackToggle: 'trackPlayback:toggle',
    trackRemoveFromMap: 'track:map:remove',
    trackShow: 'track:show',
    trackStatShow: 'trackStat:show',
    trackerInfoHidden: 'trackerInfo:hidden',
    trackerInfoHide: 'trackerInfo:hide',
    trackerInfoInterval: 'trackerInfo:interval',
    trackerInfoShow: 'trackerInfo:show',
    trackerInfoShown: 'trackerInfo:shown',
    trackersCountUpdate: 'trackersCount:update',
    trackersDraw: 'trackers:draw',
    trackersLoaded: 'trackers:loaded',
    trackersLoading: 'trackers:loading',
    vehicleSettingsUpdate: 'vehicleSettings:update',
  },
};
